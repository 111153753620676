import { createAction, createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
  loading: false,
  setAuthenticated: false,
  data: [],
  error: "",
};

const SET_AUTHENTICATED = "SET_AUTHENTICATED";
const REMOVE_ERROR = "REMOVE_ERROR";
const LOGOUT_USER = "LOGOUT_USER";

export const setAuthenticated = createAction(
  SET_AUTHENTICATED,
  (payload: boolean) => {
    return { payload: payload };
  }
);

const LOGIN_USER = "LOGIN_USER";

export const loginUserAsync = createAsyncThunk(
  LOGIN_USER,
  async (data: any, { rejectWithValue }) => {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_API_STAGING}/user/login`,
      data
    );

    if (response.status !== 200) {
      throw rejectWithValue(response.data);
    }
    return response.data;
  }
);

export const removeError = createAction(REMOVE_ERROR, () => {
  return { payload: null };
});

export const logoutUser = createAction(LOGOUT_USER);

export const loginSlice = createSlice({
  name: "login",
  initialState: initialState,
  reducers: {
    setAuthenticated: (state, action) => {
      state.setAuthenticated = action.payload;
    },
    removeError: (state) => {
      state.error = "";
    },
    logoutUser: (state) => {
      state.data = null;
      state.setAuthenticated = false;
      localStorage.clear();
      window.location.href = "/login";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setAuthenticated, (state, action) => {
      state.setAuthenticated = action.payload;
    });
    builder.addCase(logoutUser, (state, action) => {
      state.data = null;
      state.setAuthenticated = false;
      localStorage.clear();
      window.location.href = "/login";
    });
    builder.addCase(loginUserAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loginUserAsync.fulfilled, (state, action) => {
      state.loading = false;

      if (action.payload?.access_token) {
        localStorage.setItem("accessToken", action.payload.access_token);
        state.setAuthenticated = true;
      }

      window.location.href = "/dashboard";
    });
    builder.addCase(loginUserAsync.rejected, (state, action: any) => {
      state.loading = false;
      state.setAuthenticated = true;
      state.error = action?.payload?.detail ?? "Something went wrong";
      toast.error(action?.payload?.detail ?? "Something went wrong");
    });
  },
});
