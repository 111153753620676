import React, { useEffect, useState } from "react";

import { Card, Divider, Tag, Typography } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import moment from "moment";
import { useSelector } from "react-redux";

import store from "../redux/store";
import { deleteTransaction, getAllTransactions } from "./transactionsSlice";
import EditTransaction from "./EditTransaction";

const TransactionsList = () => {
  useEffect(() => {
    store.dispatch(getAllTransactions());
  }, []);

  const [editTransactionModalVisible, setEditTransactionModalVisible] =
    useState(false);

  const [activeTransaction, setActiveTransaction] = useState({});

  const { transactions } = useSelector(store.getState);

  const handleDeleteTransaction = (id: number) => {
    store.dispatch(deleteTransaction(id));
  };

  const handleModalOpen = (txn: any) => {
    setActiveTransaction(txn);
    setEditTransactionModalVisible(true);
  };

  const format1 = "YYYY-MM-DD HH:mm:ss";

  if (transactions?.data?.transactionsList?.length === 0) {
    return (
      <Card
        title="Past Transactions"
        style={{ marginTop: "20px", marginBottom: "20px", width: "100%" }}
      >
        <p>You have not added any transactions.</p>
      </Card>
    );
  }

  return (
    <>
      <Card
        title="Past Transactions"
        style={{ marginTop: "20px", marginBottom: "20px", width: "100%" }}
      >
        {transactions?.data?.transactionsList?.length > 0 &&
          transactions?.data?.transactionsList?.map((txn: any) => (
            <ul key={txn.id}>
              <li>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignContent: "center",
                  }}
                >
                  {txn.name}, Amount: ₹ {txn.amount}
                  <span>
                    <EditOutlined
                      onClick={() => handleModalOpen(txn)}
                      style={{ marginRight: "15px" }}
                    />
                    <DeleteOutlined
                      onClick={() => handleDeleteTransaction(txn.id)}
                    />
                  </span>
                </p>
                <Typography.Text type="secondary">
                  at {moment(txn.timestamp).format(format1)}{" "}
                </Typography.Text>
                <Tag
                  color={txn.transaction_type === "DEBIT" ? "magenta" : "green"}
                  style={{ borderRadius: 20 }}
                >
                  {txn.transaction_type}
                </Tag>
              </li>
              <Divider />
            </ul>
          ))}
      </Card>
      {editTransactionModalVisible && (
        <EditTransaction
          editTransactionModalVisible={editTransactionModalVisible}
          setEditTransactionModalVisible={setEditTransactionModalVisible}
          txn={activeTransaction}
        />
      )}
    </>
  );
};

export default TransactionsList;
