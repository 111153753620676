import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { Navigate } from "react-router-dom";
import { setAuthenticated } from "../auth/loginSlice";
import store from "../redux/store";

interface PrivateRouteProps {
  children: any;
}

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const accessToken = localStorage.getItem("accessToken");

  const { auth } = useSelector(store.getState);

  useEffect(() => {
    if (accessToken && !auth.setAuthenticated) {
      store.dispatch(setAuthenticated(true));
    }
  },[])

  if (auth.setAuthenticated && accessToken) {
    return children;
  }

  if (!accessToken) {
   return (
    <Navigate
      to={{
        pathname: "/login",
      }}
    />
  );
  }
};

export default PrivateRoute;
