import { Card, Typography } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import store from "../redux/store";
import { getAllTransactions } from "./transactionsSlice";

const LastTransaction = () => {
  useEffect(() => {
    store.dispatch(getAllTransactions());
  }, []);


  const {
    transactions: {
      data: { transactionsList },
    },
  } = useSelector(store.getState);

  const format1 = "YYYY-MM-DD HH:mm:ss";

  return (
    <Card
      title={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography.Text>Last Transaction</Typography.Text>
          <Link to="/all-transactions">View all </Link>
        </div>
      }
      style={{ marginBottom: "20px" }}
    >
      {transactionsList?.length > 0 ? (
        <ul>
          <li>
            <p
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignContent: "center",
              }}
            >
              {transactionsList[0].name}, Amount: ₹ {transactionsList[0].amount}
            </p>
            <Typography.Text type="secondary">
              at {moment(transactionsList[0].timestamp).format(format1)}{" "}
            </Typography.Text>
          </li>
        </ul>
      ) : (
        <Typography.Text>No records found.</Typography.Text>
      )}
    </Card>
  );
};
export default LastTransaction;
