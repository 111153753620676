import { Card, Tag } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import store from "../redux/store";
import { getAllTags } from "./tagsSlice";

const TagsCard = () => {
  useEffect(() => {
    store.dispatch(getAllTags());
  }, []);

  const { tags } = useSelector(store.getState);

  return (
    <Card
      title={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          Tags
          <Link to="/all-tags">View all</Link>
        </div>
      }
      style={{ marginTop: "20px", marginBottom: "20px" }}
    >
      {tags.tagsList && tags.tagsList.length > 0 ? (
        tags.tagsList.map((tag) => <Tag color="success" key={tag.id}>{tag.name}</Tag>)
      ) : (
        <>
          <p>You have not created any tags.</p>
          <p>
            Click here to <Link to="/all-tags">add Tags</Link>
          </p>
        </>
      )}
    </Card>
  );
};

export default TagsCard;
