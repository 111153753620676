import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Dashboard from "../pages/dashboard/Dashboard";
import Login from "../auth/login/Login";
import PageNotFound from "./PageNotFound";
import PrivateRoute from "./PrivateRoute";
import AllTransactions from "../transactions/AllTransactions";
import AllTags from "../tags/AllTags";
import Register from "../auth/register/Register";
import UpcomingFeatures from "../pages/UpcomingFeatures";
import Feedback from "../pages/Feedback";

const MainRouter = () => {
  return (
    <BrowserRouter>
      <Content />
    </BrowserRouter>
  );
};

export default MainRouter;

function Content() {
  return (
    <Routes>
      <Route index element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/all-transactions"
        element={
          <PrivateRoute>
            <AllTransactions />
          </PrivateRoute>
        }
      />
      <Route
        path="/all-tags"
        element={
          <PrivateRoute>
            <AllTags />
          </PrivateRoute>
        }
      />
      <Route
        path="/upcoming-features"
        element={
          <PrivateRoute>
            <UpcomingFeatures />
          </PrivateRoute>
        }
      />
      <Route
        path="/feedback"
        element={
          <PrivateRoute>
            <Feedback />
          </PrivateRoute>
        }
      />

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
