import React from "react";

import { Button, Card, Col, PageHeader, Row } from "antd";
import { LogoutOutlined } from "@ant-design/icons";

import { Link } from "react-router-dom";

import { logoutUser } from "../../auth/loginSlice";
import store from "../../redux/store";
import TagsCard from "../../tags/TagsCard";
import AddTransaction from "../../transactions/AddTransaction";
import LastTransaction from "../../transactions/LastTransaction";
import TotalSpendings from "../../transactions/TotalSpendings";

const Dashboard = () => {
  const handleLogoutUser = () => {
    store.dispatch(logoutUser());
  };

  return (
    <Row
      style={{
        width: "100vw",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <Col
        xs={24}
        md={24}
        lg={28}
        xl={12}
        style={{
          maxWidth: "1000px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <PageHeader
          title="Dashboard"
          extra={[
            <Button icon={<LogoutOutlined />} onClick={handleLogoutUser}>
              Logout
            </Button>,
          ]}
          style={{ width: "100%" }}
        >
          <TotalSpendings />
          <LastTransaction />
          <AddTransaction />
          <TagsCard />
          <Card title="Features and Feedback">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Link
                to="/upcoming-features"
                style={{
                  marginBottom: "10px",
                }}
              >
                View Upcoming Features
              </Link>

              <Link to="/feedback">Request Features and feedback</Link>
            </div>
          </Card>
        </PageHeader>
      </Col>
    </Row>
  );
};

export default Dashboard;
