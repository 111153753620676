import { Card, Col, Row, Typography } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import store from "../redux/store";
import { getTotal } from "./transactionsSlice";

const TotalSpendings = () => {
  useEffect(() => {
    store.dispatch(getTotal());
  }, []);

  const { transactions } = useSelector(store.getState);

  return (
    <Card
      title={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          Summary
          {/* <Link to="#">View Details</Link> */}
        </div>
      }
      style={{ marginTop: "20px", marginBottom: "20px" }}
    >
      <Row>
        <Col>
          <Typography.Text type="danger">
            Debit: {transactions?.data?.total_amount?.debit} in{" "}
            {transactions?.data?.transactions_count?.debit} transactions
          </Typography.Text>
        </Col>
      </Row>
      <Row>
        <Typography.Text type="success">
          <Col>
            credit: {transactions?.data?.total_amount?.credit} in{" "}
            {transactions?.data?.transactions_count?.credit} transactions
          </Col>
        </Typography.Text>
      </Row>
    </Card>
  );
};

export default TotalSpendings;
