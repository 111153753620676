import React from "react";

import { Result } from "antd";

const PageNotFound = () => {
  const accessToken = localStorage.getItem("accessToken");

  if (accessToken) {
    return (
      <div>
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
        />
      </div>
    );
  }
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
    />
  );
};

export default PageNotFound;
