import { Card, Input, Form, Select, Button } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import store from "../redux/store";
import { addTransaction } from "./transactionsSlice";

export type TransactionTypes = "CREDIT" | "DEBIT";

const AddTransaction = () => {
  const handleChange = (value: string) => {
    setTransactionType(value as TransactionTypes);
  };

  const [availableTags, setAvailableTags] = useState([]);

  const [transactionType, setTransactionType] =
    useState<TransactionTypes>("DEBIT");

  const handleAddTransaction = (data: any) => {
    data.transaction_type = transactionType;
    data.timestamp = Date.now();

    store.dispatch(addTransaction(data));
  };

  const { tags } = useSelector(store.getState);

  useEffect(() => {
    const newTags = [];
    tags?.tagsList?.map((tag) => {
      const localTag = {
        label: tag.name,
        value: tag.id,
      };
      newTags.push(localTag);
    });
    setAvailableTags(newTags);
  }, [tags]);

  return (
    <Card title="Add Transaction">
      <Form
        autoComplete="off"
        initialValues={{
          transaction_type: "DEBIT",
        }}
        onFinish={handleAddTransaction}
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Form.Item name="name">
          <Input name="name" placeholder="Transaction name"></Input>
        </Form.Item>
        <Form.Item name="amount">
          <Input
            name="amount"
            type="number"
            placeholder="Transaction amount"
          ></Input>
        </Form.Item>

        <Form.Item
          name="transaction_type"
          help="Debit is when you pay money, credit is when you recieve"
        >
          <Select
            onChange={handleChange}
            options={[
              {
                value: "DEBIT",
                label: "debit",
              },
              {
                value: "CREDIT",
                label: "credit",
              },
            ]}
          ></Select>
        </Form.Item>
        {/* <Form.Item name="tags"> */}
        {/* {availableTags &&
            availableTags.length > 0 &&
            availableTags.map((tag) => <>{tag.name}</>)}
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Please select"
            options={availableTags}
          /> */}
        {/* <Typography.Text type="secondary">
            You will soon be able to add tags here.
          </Typography.Text> */}
        {/* </Form.Item> */}
        <Button type="primary" htmlType="submit">
          Add Transaction
        </Button>
      </Form>
    </Card>
  );
};

export default AddTransaction;
