import { Col, PageHeader, Row } from "antd";
import React from "react";
import AddTag from "./AddTag";
import TagsList from "./TagsList";

const AllTags = () => {
  const handleBack = () => {
    window.location.href = "/dashboard";
  };

  return (
    <Row
      style={{
        width: "100vw",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <Col
        xs={24}
        md={24}
        lg={28}
        xl={12}
        style={{
          width: "100%",
          maxWidth: "1000px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <PageHeader
          title="All tags"
          onBack={handleBack}
          style={{ width: "100%" }}
        >
          <TagsList />
          <AddTag />
        </PageHeader>
      </Col>
      <Col></Col>
    </Row>
  );
};

export default AllTags;
