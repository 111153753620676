import { createAction, createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
import { toast } from "react-toastify";

import store from "../redux/store";
import { loginUserAsync } from "./loginSlice";

const initialState = {
  loading: false,
  setAuthenticated: false,
  data: [],
  error: "",
};

const REMOVE_ERROR = "REMOVE_ERROR";

const REGISTER_USER = "REGISTER_USER";

export const registerUserAsync = createAsyncThunk(
  REGISTER_USER,
  async (data: any, { rejectWithValue, fulfillWithValue }) => {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_API_STAGING}/user/register`,
      data
    );

    if (response.status !== 200) {
      toast.error(response?.data?.detail ?? "Something went wrong");
      throw rejectWithValue(response.data);
    }

    toast.success("Registration Successful. Proceed to login");

    const formData = new FormData();
    formData.append("username", data.email);
    formData.append("password", data.password);

    store.dispatch(loginUserAsync(formData));

    fulfillWithValue(data);

    return await response.data;
  }
);

export const removeError = createAction(REMOVE_ERROR, () => {
  return { payload: null };
});

export const registerSlice = createSlice({
  name: "registerSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(registerUserAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(registerUserAsync.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(registerUserAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = "Invalid Credentials";
    });
  },
});
