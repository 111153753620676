import React from "react";

import { Col, List, PageHeader, Row } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";

const UpcomingFeatures = () => {
  const handleBack = () => {
    window.location.href = "/dashboard";
  };

  const upcomingFeaturesList = [
    " Ability to add tags to transactions",
    "Ability to add notes to transactions",
    "Ability to add attachments to transactions",
    "Ability to Register and Login with Google",
    "Filter, sort and search transactions",
    "Ability to Change password and Forget Password",
    "Charts for spendings by tags ",
  ];

  return (
    <Row
      style={{
        width: "100vw",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <Col
        xs={24}
        md={24}
        lg={28}
        xl={12}
        style={{
          maxWidth: "1000px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <PageHeader
          title="Upcoming Features"
          onBack={handleBack}
          style={{ width: "100%" }}
        >
          <List
            bordered
            dataSource={upcomingFeaturesList}
            renderItem={(item) => (
              <List.Item
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                <CheckCircleOutlined style={{ marginRight: "10px" }} /> {item}
              </List.Item>
            )}
          />
        </PageHeader>
      </Col>
    </Row>
  );
};

export default UpcomingFeatures;
