import { Col, PageHeader, Row } from "antd";
import React from "react";
import TransactionsList from "./TransactionsList";

function AllTransactions() {
  const handleBack = () => {
    window.location.href = "/dashboard";
  };
  return (
    <Row
      style={{
        width: "100vw",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <Col
        xs={24}
        md={24}
        lg={28}
        xl={12}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <PageHeader
          title="All Transactions"
          onBack={handleBack}
          style={{ width: "100%" }}
        >
          <TransactionsList />
        </PageHeader>
      </Col>
    </Row>
  );
}

export default AllTransactions;
