import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import { loginSlice } from "../auth/loginSlice";
import { registerSlice } from "../auth/registerSlice";
import { tagsSlice } from "../tags/tagsSlice";
import { transactionsSlice } from "../transactions/transactionsSlice";

const store = configureStore({
  reducer: {
    auth: loginSlice.reducer,
    transactions: transactionsSlice.reducer,
    tags: tagsSlice.reducer,
    register: registerSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;
