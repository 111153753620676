import React from "react";

import { Card, Typography, Input, Form, Button, Row, Col } from "antd";

import store from "../../redux/store";
import { useSelector } from "react-redux";
import { registerUserAsync } from "../registerSlice";
import { Link } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import FullScreenSpinner from "../../components/FullScreenSpinner";

const Register = () => {
  const { auth } = useSelector(store.getState);

  const handleRegisterFormSubmit = (data: any) => {
    const dataToSubmit = {
      email: data.email,
      firstname: data.firstName,
      lastname: data.lastName,
      password: data.password,
    };

    store.dispatch(registerUserAsync(dataToSubmit));
  };

  if (auth?.loading) {
    return <FullScreenSpinner />;
  } else {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography.Title>Daily Transactions</Typography.Title>
          <Typography.Text type="secondary">
            Stay on top of your spendings
          </Typography.Text>
        </div>

        <Card
          title="Register"
          style={{ padding: "20px", width: "100vw", maxWidth: "600px" }}
        >
          <Form
            autoComplete="off"
            onFinish={handleRegisterFormSubmit}
            layout="inline"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Row style={{ width: "100%" }}>
              <Col style={{ width: "100%" }}>
                <Form.Item name="firstName" style={{ margin: "10px" }}>
                  <Input
                    type="text"
                    placeholder="first name"
                    bordered={true}
                    size="large"
                    required
                    style={{ width: "100%" }}
                  ></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ width: "100%" }}>
              <Col style={{ width: "100%" }}>
                <Form.Item name="lastName" style={{ margin: "10px" }}>
                  <Input
                    type="text"
                    placeholder="last name"
                    bordered={true}
                    size="large"
                    style={{ width: "100%" }}
                  ></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ width: "100%" }}>
              <Col style={{ width: "100%" }}>
                <Form.Item name="email" style={{ margin: "10px" }}>
                  <Input
                    required
                    type="text"
                    placeholder="email"
                    bordered={true}
                    size="large"
                    style={{ width: "100%" }}
                  ></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ width: "100%" }}>
              <Col style={{ width: "100%" }}>
                <Form.Item name="password" style={{ margin: "10px" }}>
                  <Input.Password
                    placeholder="password"
                    bordered={true}
                    required
                    size="large"
                    style={{ width: "100%" }}
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  ></Input.Password>
                </Form.Item>
              </Col>
            </Row>

            <Button type="primary" htmlType="submit" style={{ margin: "10px" }}>
              Register
            </Button>
          </Form>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              paddingTop: "10px",
            }}
          >
            <Typography>
              Already a user? <Link to="/login">Login</Link>
            </Typography>
          </div>
        </Card>
      </div>
    );
  }
};

export default Register;
