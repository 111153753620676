import { Card, Input, Form, Button } from "antd";
import React from "react";
import store from "../redux/store";
import { addTag } from "./tagsSlice";

const AddTag = () => {
  const handleAddTag = (data: any) => {
    store.dispatch(addTag(data));
  };

  return (
    <Card title="Add Tag">
      <Form
        autoComplete="off"
        onFinish={handleAddTag}
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Form.Item name="name">
          <Input name="name" placeholder="Name"></Input>
        </Form.Item>

        <Button type="primary" htmlType="submit">
          Add Tag
        </Button>
      </Form>
    </Card>
  );
};

export default AddTag;
