import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
import { toast } from "react-toastify";

interface TransactionsState {
  loading: boolean;
  data: any;
  error: string;
}

const initialState = {
  loading: false,
  data: {
    total_amount: {
      credit: 0,
      debit: 0,
    },
    transactions_count: {
      credit: 0,
      debit: 0,
    },
    transactionsList: [
      {
        id: 0,
        name: "",
        amount: 0,
        transction_type: "credit",
        timestamp: "2021-07-01T12:00:00.000Z",
      },
    ],
  },
  error: "",
} as TransactionsState;

export const getTotal = createAsyncThunk("/login", async () => {
  try {
    const result: any = await axios.get(
      `${process.env.REACT_APP_BACKEND_API_STAGING}/transaction/total`
    );
    return result.data;
  } catch (err) {
    return err.message;
  }
});

export const addTransaction = createAsyncThunk(
  "/transaction",
  async (transactionData: any) => {
    try {
      const result: any = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_STAGING}/transaction`,
        transactionData
      );
      return result.data;
    } catch (err) {
      return err.message;
    }
  }
);

export const editTransaction = createAsyncThunk(
  "/edit-transaction",
  async (transactionData: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result: any = await axios.put(
        `${process.env.REACT_APP_BACKEND_API_STAGING}/transaction/${transactionData.id}`,
        transactionData
      );

      if (result.status === 200) {
        return fulfillWithValue(result.data);
      } else {
        return rejectWithValue(result.data);
      }
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const getAllTransactions = createAsyncThunk(
  "/get-transactions",
  async () => {
    try {
      const result: any = await axios.get(
        `${process.env.REACT_APP_BACKEND_API_STAGING}/transaction/all`
      );
      return result.data;
    } catch (err) {
      return err.message;
    }
  }
);

export const deleteTransaction = createAsyncThunk(
  "/delete-transactions",
  async (id: number) => {
    try {
      const result: any = await axios.delete(
        `${process.env.REACT_APP_BACKEND_API_STAGING}/transaction/${id}?txn_id=${id}`
      );
      return result;
    } catch (err) {
      return err.message;
    }
  }
);

export const transactionsSlice = createSlice({
  name: "transactions",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTotal.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getTotal.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });

    builder.addCase(getTotal.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(addTransaction.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(addTransaction.fulfilled, (state, action) => {
      state.loading = false;
      window.location.reload();
    });

    builder.addCase(addTransaction.rejected, (state, action) => {
      state.loading = false;
      console.log("transaction add failed ... ", action.payload);
    });

    builder.addCase(editTransaction.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(editTransaction.fulfilled, (state, action) => {
      state.loading = false;

      const updatedTransaction: any = action.payload;

      state.data.transactionsList = state.data?.transactionsList.map(
        (transaction: any) => {
          if (transaction.id === updatedTransaction.id) {
            return action.payload;
          }
          return transaction;
        }
      );

      toast.success("Transaction updated successfully");
    });

    builder.addCase(editTransaction.rejected, (state, action) => {
      state.loading = false;
      toast.error("Transaction update failed");
      console.log("transaction update failed ... ", action.payload);
    });

    builder.addCase(getAllTransactions.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getAllTransactions.fulfilled, (state, action) => {
      state.loading = false;
      state.data.transactionsList = action.payload;
    });

    builder.addCase(getAllTransactions.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(deleteTransaction.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(deleteTransaction.fulfilled, (state, action) => {
      state.loading = false;
      window.location.reload();
    });

    builder.addCase(deleteTransaction.rejected, (state, action) => {
      state.loading = false;
    });
  },
});
