import { CloseOutlined } from "@ant-design/icons";
import { Card, Tag } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import store from "../redux/store";
import { deleteTag, getAllTags } from "./tagsSlice";

const TagsList = () => {
  useEffect(() => {
    store.dispatch(getAllTags());
  }, []);

  const { tags } = useSelector(store.getState);

  const handleDeleteTag = (id: number) => {
    store.dispatch(deleteTag(id));
    console.log("tops");
  };

  if (tags?.tagsList?.length === 0) {
    return (
      <Card title={"Tags"} style={{ marginTop: "20px", marginBottom: "20px" }}>
        <p>You have not created any tags.</p>
      </Card>
    );
  }

  return (
    <Card
      title={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p>Tags</p>
        </div>
      }
      style={{ marginTop: "20px", marginBottom: "20px" }}
    >
      <div style={{ display: "flex" }}>
        {tags.tagsList &&
          tags.tagsList.length > 0 &&
          tags.tagsList.map((tag) => (
            <Tag
              key={tag.id}
              color="success"
              style={{
                display: "flex",
                alignItems: "center",
                margin: "5px",
              }}
            >
              {tag.name}
              <CloseOutlined
                onClick={() => handleDeleteTag(tag.id)}
                style={{ marginLeft: "5px" }}
              />
            </Tag>
          ))}
      </div>
    </Card>
  );
};

export default TagsList;
