import { ErrorBoundary } from "react-error-boundary";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import "./App.css";
import React from "react";
import MainRouter from "./routing/routes";
import store from "./redux/store";

import { Provider } from "react-redux";
import axios from "axios";
import { message } from "antd";

axios.interceptors.request.use(function (config) {
  if (localStorage.getItem("accessToken")) {
    const token = localStorage.getItem("accessToken");

    if (config.headers && token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
  }
  return config;
});

axios.interceptors.request.use((req) => {
  return req;
});

const App = () => {
  function ErrorFallback({ error, resetErrorBoundary }: any) {
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    );
  }

  axios.interceptors.response.use(
    (res) => res,
    (err) => {
      console.log("ERROR from API ---->", err.response);

      if (err?.response?.status === 401) {
        console.log("status 401 -->", err.response?.data?.message);

        message.error("Session expired. Logging out.");
        localStorage.clear();
        window.location.reload();
      }

      return err.response;
    }
  );

  return (
    <>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
        }}
      >
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Provider store={store}>
          <MainRouter />
        </Provider>
      </ErrorBoundary>
    </>
  );
};

export default App;
