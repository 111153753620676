import React from "react";

import { Spin } from "antd";

const FullScreenSpinner = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Spin size="large" tip="Loading..." />
    </div>
  );
};

export default FullScreenSpinner;
