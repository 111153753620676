import React from "react";

import { Card, Col, PageHeader, Row } from "antd";

const Feedback = () => {
  const handleBack = () => {
    window.location.href = "/dashboard";
  };

  return (
    <Row
      style={{
        width: "100vw",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <Col
        xs={24}
        md={24}
        lg={28}
        xl={12}
        style={{
          maxWidth: "1000px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <PageHeader
          title="Feedback"
          onBack={handleBack}
          style={{ width: "100%" }}
        >
          <Card>You will be able to provide feedback here</Card>
        </PageHeader>
      </Col>
    </Row>
  );
};

export default Feedback;
