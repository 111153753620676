import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";

const initialState = {
  loading: false,
  data: {},
  error: "",
} as any;

export const getAllTags = createAsyncThunk("/all-tags", async () => {
  try {
    const result: any = await axios.get(
      `${process.env.REACT_APP_BACKEND_API_STAGING}/tag/all`
    );
    return result.data;
  } catch (err) {
    return err.message;
  }
});

export const addTag = createAsyncThunk("/tag", async (tagData: any) => {
  try {
    const result: any = await axios.post(
      `${process.env.REACT_APP_BACKEND_API_STAGING}/tag`,
      tagData
    );
    return result.data;
  } catch (err) {
    return err.message;
  }
});

export const deleteTag = createAsyncThunk("/delete-tag", async (id: number) => {
  try {
    const result: any = await axios.delete(
      `${process.env.REACT_APP_BACKEND_API_STAGING}/tag/${id}?tag_id=${id}`
    );
    return result.data;
  } catch (err) {
    return err.message;
  }
});

export const tagsSlice = createSlice({
  name: "tags",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllTags.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getAllTags.fulfilled, (state, action) => {
      state.loading = false;
      state.tagsList = action.payload;
    });

    builder.addCase(getAllTags.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(addTag.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(addTag.fulfilled, (state, action) => {
      state.loading = false;
      window.location.reload();
    });

    builder.addCase(addTag.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(deleteTag.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(deleteTag.fulfilled, (state, action) => {
      state.loading = false;
      window.location.reload();
    });

    builder.addCase(deleteTag.rejected, (state, action) => {
      state.loading = false;
    });
  },
});
