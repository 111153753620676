import React, { useState } from "react";

import { Button, Form, Input, Modal, Select } from "antd";

import { TransactionTypes } from "./AddTransaction";
import store from "../redux/store";
import { editTransaction, getAllTransactions } from "./transactionsSlice";

const EditTransaction = (props: any) => {
  const [form] = Form.useForm();

  const { txn, editTransactionModalVisible, setEditTransactionModalVisible } =
    props;

  const [transactionType, setTransactionType] = useState<TransactionTypes>(
    txn.transaction_type
  );

  const handleCancel = () => {
    setEditTransactionModalVisible(false);
  };

  const handleChange = (value: string) => {
    setTransactionType(value as TransactionTypes);
  };

  const handleEditTransaction = (data: any) => {
    data.id = txn.id;
    data.transaction_type = transactionType;

    store.dispatch(editTransaction(data));
    setEditTransactionModalVisible(false);
    store.dispatch(getAllTransactions());
  };

  return (
    <Modal
      open={editTransactionModalVisible}
      onCancel={handleCancel}
      onOk={handleEditTransaction}
      okText="Update Transaction"
      title="Edit Transaction"
      footer={[
        <Button key={"cancel"} onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          form="edit-transaction-form"
          key="submit"
          htmlType="submit"
          type="primary"
        >
          Update
        </Button>,
      ]}
    >
      <Form
        id="edit-transaction-form"
        form={form}
        autoComplete="off"
        initialValues={{
          transaction_type: txn.transaction_type,
          amount: txn.amount,
          name: txn.name,
        }}
        onFinish={handleEditTransaction}
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Form.Item name="name">
          <Input name="name" placeholder="Transaction name"></Input>
        </Form.Item>
        <Form.Item name="amount">
          <Input
            name="amount"
            type="number"
            placeholder="Transaction amount"
          ></Input>
        </Form.Item>

        <Form.Item
          name="transaction_type"
          help="Debit is when you pay money, credit is when you recieve"
        >
          <Select
            onChange={handleChange}
            options={[
              {
                value: "DEBIT",
                label: "debit",
              },
              {
                value: "CREDIT",
                label: "credit",
              },
            ]}
          ></Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditTransaction;
